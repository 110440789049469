<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Nft</h5>
    <Button
      label="Добавить nft"
      class="p-button-primary"
      @click="clear"
    />
    <DataTable
      :value="nftList"
      data-key='id'
      v-model:wallets="nftList"
      responsiveLayout="scroll"
    >
      <Column header='Название' field='productName'/>
      <Column header='Описание' field='description'/>
      <Column header='Изображение' field='image'/>
      <Column header='Компания' field='companyName'/>
      <Column class="bg-white border-none flex justify-content-end">
        <template #body="slot">
          <Button
            :disabled='slot.data.issuedAt !== undefined'
            icon="pi pi-pencil"
            class="p-button-rounded p-button-warning m-1"
            @click='edit(slot.data)'
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger m-1"
            @click='del(slot.data)'
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    v-model:visible='createEditDialog'
    :header="header"
    style='width:700px;'
    class='p-fluid'
  >
    <div class='flex'>
      <div class='flex-column col-6'>
        <label id='productname'>Название проекта</label>
        <InputText id='productname' v-model='productName'/>
        <div class='distance'>
          <label for='companyname'>Компания</label>
          <Dropdown
            id='companyname'
            v-model='selectedCompany'
            :options='userCompanyList'
            option-label='companyName'
          />
        </div>
        <div class='distance'>
          <label id='description'>Описание</label>
          <InputText id='description' v-model='description' />
        </div>
      </div>
      <div class='flex-column col-6'>
        <label id='serialnumber'>Серийный номер</label>
        <InputText id='serialnumber' v-model='serialNumber'/>
        <div class='distance'>
          <label id='section'>Секция</label>
          <Dropdown
            id='section'
            v-model='selectedSection'
            :options='sectionList'
            option-label='name'
          />
        </div>
        <div class='distance'>
          <label id='image'>Изображение</label>
          <InputText id='image' v-model='image' type='file' @change='selectFile'/>
        </div>
      </div>
    </div>
    <div class='distance_button'>
      <small class='p-error'>
        <span v-if='!allField'>Заполните все поля</span>
        <span v-else>&nbsp;</span>
      </small>
      <Button
        :disabled='!allField'
        :label="button"
        class="p-button-primary"
        @click='modifyNft === null ? addNft() : editNft()'
      />
    </div>
  </Dialog>

  <Dialog
    v-model:visible="deleteDialog"
    header="Удалить nft?"
    :modal="true"
  >
    <div class="confirmation-content">
      <p class="text-center p-error">
        Вы уверены, что хотите удалить?
      </p>
      <p class="text-center">
        <b>{{ modifyNft.productName }}</b>
      </p>
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteDialog=false"
      />
      <Button
        label="Да"
        icon="pi pi-check"
        class="p-button-text"
        @click='deleteNft'
      />
    </template>
  </Dialog>
</template>

<script>

import moment from 'moment'

export default {
  name: 'ManagerNft',
  data() {
    return {
      nftList: [],
      companyList: [],
      userCompanyList: [],
      sectionList: [],
      createEditDialog: false,
      deleteDialog: false,
      productName: null,
      selectedCompany: null,
      selectedSection: null,
      description: null,
      serialNumber: null,
      modifyNft: null,
      image: null,
      imageFile: null
    }
  },
  async mounted() {
    await this.getCompanyList();
    await this.getNftList();
    await this.getSectionList();
  },
  computed:{
    allField(){
      return this.productName !== null &&
        this.selectedCompany !== null &&
        this.description !== null &&
        this.image !== null &&
        this.serialNumber !== null &&
        this.selectedSection != null
    },
    header(){
      return this.modifyNft === null ? "Добавить nft" : "Редактировать nft"
    },
    button(){
      return this.modifyNft === null ? "Добавить" : "Редактировать"
    }
  },
  methods:{
    async getNftList() {
      try {
        const resp = await this.axios.get("api/nfts");
        const nftData = resp.data;
        for (const item of nftData)
        {
          item.displayId = this.nftList.length;
          [item.companyId, item.companyName, item.userId] = this.getCompanyInfo(item.company)
          this.nftList.push(item);
        }
      }
      catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in api/nfts api call',
          group: 'info',
          life: 3000,
        });
      }
    },

    getCompanyInfo(value) {
      if (this.companyList.length === 0) return [0,"",0];
      const idPos = value.lastIndexOf('/') + 1;
      if (idPos === -1) return [0,"No name",0];
      const id = value.slice(idPos);
      const company = this.companyList.find(item => item.id == id);
      return [
        id,
        company === undefined ? "No name" : company.companyName,
        company === undefined ? 0 : company.userId
      ];
    },

    async getCompanyList() {
      try{
        const resp = await this.axios.get("api/companies");
        const companyData = resp.data;
        for (const item of companyData)
        {
          const userId = item.user.slice(item.user.lastIndexOf('/') + 1);
          this.companyList.push({id: item.id, companyName: item.nameRus, userId: userId});
        }
        this.getUserCompanyList();
      }
      catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in api/companies api call',
          group: 'info',
          life: 3000,
        });
      }
    },

    getUserCompanyList(){
      const loginId = this.$auth.user().id;
      for (const item of this.companyList){
        if (loginId == item.userId){
          this.userCompanyList.push(item);
        }
      }
    },

    async getSectionList() {
      try{
        const resp = await this.axios.get("api/section_markets");
        this.sectionList = resp.data;
      }
      catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in api/section api call',
          group: 'info',
          life: 3000,
        });
      }
    },

    async addNft() {
      try{
        const headers = {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        }

        let params = new FormData();
        params.append("productName", this.productName);
        //params.append("company", `api/companies/${this.selectedCompany.id}`);
        params.append("company_id", this.selectedCompany.id);
        params.append("description", this.description);
        params.append("image", this.imageFile?.name);
        params.append("imageFile", this.imageFile, this.imageFile?.name);
        params.append("serialNumber", this.serialNumber);
        params.append("createdAt", moment().format("DD.MM.YYYY"));
        params.append("section_id", this.selectedSection.id);
        params.append("section_name", this.selectedSection.name);
        params.append("section_slug", this.selectedSection);
        params.append("isVirtual", 'true');

        const resp = await this.axios.post("api/nfts/post_mp", params, {headers: headers});
        this.showNewNft(resp.data);
        this.createEditDialog = false;
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'New nft created',
          group: 'info',
          life: 3000,
        });
      }
      catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in api/nft api call',
          group: 'info',
          life: 3000,
        });
      }
    },

    showNewNft(value) {
      const item = value;
      item.displayId = this.nftList.length;
      item.companyId = this.selectedCompany.companyId;
      item.companyName = this.selectedCompany.companyName;
      this.nftList.push(item);
    },

    showEditNft(value) {
      this.nftList[this.modifyNft.displayId] = value;
      this.nftList[this.modifyNft.displayId].companyId = this.selectedCompany.companyId;
      this.nftList[this.modifyNft.displayId].companyName = this.selectedCompany.companyName;
    },

    clear() {
      this.modifyNft = null;
      this.productName = null;
      this.selectedCompany = null;
      this.selectedSection = null;
      this.description = null;
      this.image = null;
      this.serialNumber = null;
      this.createEditDialog = true;
    },

    edit(value) {
      this.modifyNft = value;
      this.productName = value.productName;
      this.selectedCompany = this.userCompanyList.find(item => item.id == value.companyId);
      this.selectedSection = this.sectionList.find(item => item.id == value.section.id);
      this.description = value.description;
      this.serialNumber = value.serialNumber;
      this.image = null;
      this.createEditDialog = true;
    },

    del(value) {
      this.modifyNft = value;
      this.deleteDialog = true;
    },

    async editNft(){
      try{
        const headers = {
          accept: "application/json",
          "Content-Type": "application/merge-patch+json",
        }

        const params = {
        productName: this.productName,
        company: `api/companies/${this.selectedCompany.id}`,
        description: this.description,
        image: this.image,
        serialNumber: this.serialNumber,
        createdAt: moment().format("DD.MM.YYYY"),
        section: this.selectedSection,
        isVirtual: true};

        const resp = await this.axios.patch(`api/nfts/${this.modifyNft.id}`, params, {headers: headers});
        this.createEditDialog = false;
        this.showEditNft(resp.data);
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Existing nft edited',
          group: 'info',
          life: 3000,
        });
      }
      catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in edited nft',
          group: 'info',
          life: 3000,
        });
      }
    },

    async deleteNft (){
      this.deleteDialog = false;
      try{
        await this.axios.delete(`api/nfts/${this.modifyNft.id}`);
        this.nftList.splice(this.modifyNft.displayId, 1);
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Nft deleted successfully',
          group: 'info',
          life: 3000,
        });
      }
      catch (e)
      {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Delete nft error',
          group: 'info',
          life: 3000,
        });
      }
    },

    selectFile(e) {
      this.imageFile = e.target.files[0];
    }
  }
}
</script>

<style scoped>
.distance{
  margin-top: 7px;
}
.distance_button
{
  margin-top: 20px;
}
</style>